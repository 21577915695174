import "./Main.css"
import Logo from "../Logo-layout/Logo"
import Header from "../header-layout/Header"
import ElizabethIntroduction from "../intro-layout/Intro"
import ElizabethAbout from "../about-us-layout/About"
import ServicesSection from "../services-layout/Services"
import ExperienceTimeline from "../experience/Experience"
import Clients from "../partners-layout/Clients"
import Footer from "../footer-layout/Footer"

import { Analytics } from '@vercel/analytics/react';
import { Element } from 'react-scroll';


function Maincontent() {
    const sections = [
        { id: 'home', label: 'Home' },
        { id: 'about', label: 'About' },
        { id: 'services', label: 'Skills' },
        { id: 'experience', label: 'Projects' },
        // { id: 'clients', label: 'TESTIMONIALS' },
        // { id: 'contact', label: 'CONTACT' },
    ];

    return (
        <div className="main-content" >
            <Analytics/>
            <Logo/> 
            <Header sections={sections}  />
            <div className="component-container" >
                <Element name="home">
                    <ElizabethIntroduction />
                </Element>
            </div>
            
        <div className="component-container" >
                    <Element name="about"> 
                        <ElizabethAbout />
                    </Element>
            </div> 
           
            <Element name="services" >
                <ServicesSection />
            </Element> 

            <Element name="experience">
                <ExperienceTimeline />
            </Element> 
            
            {/* <Element name="clients" >
                <Clients />
            </Element>
           
            <Element name="contact" >
                <Footer />
            </Element>  */}
          
        </div>
    );
}
export default Maincontent