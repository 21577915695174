import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import 'animate.css/animate.min.css';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';

function Header({ sections }) {
  const [activeSection, setActiveSection] = useState(null);

  const handleSetActive = (sectionId) => {
    setActiveSection(sectionId);
  };

  return (
    <header className="header">
      <Navbar  expand="lg" className="navbar">
        <Container >
          <Navbar.Brand href="#" className="header-left">
            <span style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
              <span style={{ fontSize: '4vh', marginBottom: '0.01em' }}>E</span>
              <span style={{ marginLeft: '-22px', marginBottom: '0.2em' }}>lizabeth Mutitu</span>
            </span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {sections.map((section) => (
                <Nav.Link
                  key={section.id}
                  as={ScrollLink}
                  to={section.id}
                  smooth={true}
                  duration={500}
                  activeClass="active-nav"
                  spy={true}
                  offset={-70}
                  onSetActive={() => handleSetActive(section.id)}
                  style={{ color: activeSection === section.id ? '#FF826E' : null }}
                >
                  {section.label}
                </Nav.Link>
              ))}
            </Nav>
            <div className="contact-info ml-auto d-flex">
              <div className="phone d-flex align-items-center mr-3">
                <LocalPhoneOutlinedIcon style={{ marginRight: '5px' }} />
                <span>+254 719 814 734</span>
              </div>
              <div className="email d-flex align-items-center">
                <MailOutlinedIcon style={{ marginRight: '5px' }} />
                <span>gathonimutitu80@gmail.com</span>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
