import React from "react";
import './Services.css';
import { motion } from 'framer-motion';
import { Card, CardContent, Typography } from "@mui/material";

const ServicesSection = () => {
  const keySkills = [
    'Capacity Development',
    'Grants Managment',
    'Program Managment',
    'Partner Mapping',
    'Project Implementation',
    'Financial Management',
   
  ];
  const skillData = [
    'I provide tailored strategies and training to enhance your organization\'s skills, resources and performance for sustainable growth.',
    'I secure, administer and monitor grants to ensure effective use of funds and compliance with regulations.',
    'I ensure your projects stay on track, meet objectives and deliver impactful results through expert oversight and coordination.',
    'I identify and engage strategic partners to strengthen collaborations and maximize project impact.',
    'I coordinate and execute projects, ensuring efficient delivery, stakeholder engagement and successful outcomes.',
    'I deliver comprehensive solutions for budgeting, financial analysis and risk management to optimize your organization\'s financial health and sustainability.',
  ];

  return (
    <section className="service-container">
      <div className="service-heading" style={{marginBottom:'50px',marginRight:'74%',textAlign:'left'}}>
      <Typography style={{ fontFamily: 'Jost', color: '#333333' }}>
          MY SKILLSET
      </Typography>

      <Typography className="flex-typography" style={{ textAlign:'left',fontFamily: 'DM Serif Display', color: '#333333',fontWeight:400}}>
          How I Can Help You
      </Typography>
      </div>
        
      <div className="row">
        {keySkills.map((skill, index) => (
          <div key={index} className="column">
              <motion.div
            initial={{scale:1.3}}
            whileInView={{ scale: 1, transition: { duration: 1 } }}>
            <Card style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
              <CardContent>
                <Typography style={{ fontFamily: 'Jost', color: '#333333' }}>
                  {index < 9 ? `0${index + 1}.` : index + 1}
                  </Typography>
                <Typography  style={{ fontFamily: 'DM Serif Display', color: '#333333',fontSize:'23px',fontWeight:500 }}>
                  {skill}
                </Typography>
                <Typography style={{ fontFamily: 'Jost', color: '#333333',lineHeight:'32px',fontSize:'16px' }}>
                  {skillData[index]}
                </Typography>
              </CardContent>
            </Card>
            </motion.div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
