import "./Logo.css"
import React, { useEffect, useState } from 'react';

const Logo = () => {
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    // Add a short delay to show the logo for a few seconds before transitioning out
    const logoTimeout = setTimeout(() => {
      setShowLogo(false);
    }, 3000); // Adjust the time (in milliseconds) to control how long the logo is displayed

    return () => clearTimeout(logoTimeout);
  }, []);

  return (
    <div className={`logo ${showLogo ? 'show' : 'hide'}`}>
      {/* {showLogo && <img src="/img/logo.jpeg" alt="Company Logo" />} */}
      <div className="soon-container">
          <div className="logo-container">
            <div className="monogram">E</div>
            <div className="name">Elizabeth Mutitu</div>
          </div>
      </div>
    </div>
  );
};

export default Logo;
