import "./About.css";
import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';

const ElizabethAbout = () => {
  const [showAbout, setShowAbout] = useState(false);

  useEffect(() => {
    const introTimeout = setTimeout(() => {
      setShowAbout(true);
    }, 3000); // Adjust the time (in milliseconds) to control the delay

    return () => clearTimeout(introTimeout);
  }, []);

  return (
    <div className=" elizabeth-main">
      <div className={`elizabeth-about ${showAbout ? 'show' : ''}`}>
        <div className="content">
          <motion.div
            initial={{scale:1.2}}
            whileInView={{ scale: 1, transition: { duration: 0.7 } }}
            className="image-container"
          >
            <img
              src="/img/2.webp"
              alt="Elizabeth's Profile"
              className="random-image"
            />
          </motion.div>
          <div className="paragraph">
            <motion.p
              whileInView={{ y: 0, transition: { duration: 1} }}
              initial={{ y: 50 }}
            >
               <h3 className="intro-heading">ABOUT ME
               <img src="img/file.svg" alt="Curved Arrow" className="arrow-icon"style={{ transform: 'rotate(360deg) translateY(-30px) scaley(-1)', width: '50px', height: '50px' }}/>
               </h3>
               <h2 className="about-intro"> Because the world needs great solutions...</h2>
              <br />
              I use my expertise in economics, statistics and finance to champion human rights, democracy, and gender equality. I have contributed to projects that improve access to essential resources and enhance community well-being.
              <br></br>
              <br></br>
              Bridging the gap between policy and action, I work collaboratively to implement projects driven by a commitment to creating a more equitable and sustainable future.
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElizabethAbout;
