import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider'; 
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import 'bootstrap/dist/css/bootstrap.min.css';

const experienceData = [
  {
    title: 'Project 1',
    projectname: 'Empowering Communities',
    description:
      'As part of a dedicated team, I have worked on empowering communities through comprehensive training on human rights, civic duties, climate change, gender inequality and democracy. We have facilitated adult literacy classes in Narok and Nakuru counties, provided training on smart climate farming, and promoted entrepreneurship, fostering sustainable development and self-reliance.',
    role: 'Role: Overseeing community-based organizations & implementing well-being projects',
    imagePosition: 'left', // To display image on the left
    imageUrl: '/img/img111.webp' // Replace with your image URL
  },
  {
    title: 'Project 2',
    projectname: 'Wajibu Wetu',
    description:
      'Wajibu Wetu aims to promote human rights, democracy and gender equality through activism. As a team member, I contributed to this project by supporting initiatives like funding the XYZ show, which raises awareness of people\'s rights through art. Additionally, the Wajibu wetu project empowered young girls from disadvantaged backgrounds by partnering with organizations like AkiraChix, a tech organization that equips these girls with essential tech skills.',
    role: 'Role: Managing finances',
    imagePosition: 'right', // To display image on the right
    imageUrl: '/img/4.webp' // Replace with your image URL
  },
  {
    title: 'Project 3',
    projectname: 'The Narok Water Project',
    description:
      'The aim of the Narok water project was to provide easy access to water and reduce the burden on women and children who traveled long distances to fetch it. As part of this initiative, our team partnered with the county government to lay a 20km pipeline from Naivasha to Narok. This project not only improved water accessibility but also ensured that children no longer missed school because they had to fetch water for their families.',
    role: 'Role: Managing finances',
    imagePosition: 'left', // To display image on the left
    imageUrl: '/img/5.webp' // Replace with your image URL
  },
  {
    title: 'Project 4',
    projectname: 'Sexual Reproductive Health & Rights',
    description:
      'I served as the Kenyan focal person for the RFSU organization in Sweden, which grants small organizations in Kenya with the goal of enabling sexual reproductive health and rights. In this role, I conducted capacity assessments, performed context analysis and monitored the granted organizations. Additionally, I planned and coordinated virtual and in-person meetings and conferences, handling all logistics for the project in Kenya.',
    role: 'Role: Managing finances',
    imagePosition: 'right', // To display image on the left
    imageUrl: '/img/img112.webp' // Replace with your image URL
  },
];

function Experience() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  return (
    <div className="container experience-main-content">
      <div className="row">
        <div className="col-12">
          <Typography className="mt-4 mb-4" style={{marginRight:'55%'}} textAlign="left" fontSize="32px" component="span" fontWeight="bold" fontFamily="DM Serif Display">
            Some of the projects I have undertaken:
          </Typography>
          <br></br>
          <br></br>
        </div>
      </div>
      {experienceData.map((item, index) => (
        <div key={index} className="row mb-5">
          <div className={`col-12 col-md-6 ${index % 2 === 0 ? 'order-md-2' : ''}`}>
            <img src={item.imageUrl} alt={item.title} className="img-fluid" />
          </div>
          <div className="col-12 col-md-6">
            <Typography className="text-left" fontFamily="Jost" fontSize="14px" fontWeight="Bold">
              {item.title}
            </Typography>
            <Typography className="text-left mb-2" fontFamily="DM Serif Display" fontSize="40px">
              {item.projectname}
            </Typography>
            <Typography className="text-left text-muted mb-2" fontFamily="Jost" fontSize="16px">
              {item.description}
            </Typography>
            <Typography className="text-left text-muted font-weight-bold mb-2" fontFamily="Jost" fontWeight="Bold">
              {item.role}
            </Typography>
            <Typography className="text-left text-muted" fontFamily="Jost" style={{ textDecoration: 'underline' }}>
              {item.readMore}
            </Typography>
          </div>
        </div>
      ))}

      <div className="contact-page mt-5">
        <div className="d-flex align-items-center">
          <Typography fontSize="96px" fontFamily="DM Serif Display" color="#333333" fontWeight={400}>
            E
          </Typography>
          <Divider className="flex-grow-1 mx-3" orientation="horizontal" style={{ backgroundColor: '#000000', height: '2px' }} />
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-5 mb-4 mb-md-0">
            <Typography textAlign="left" fontWeight="bold" fontFamily="DM Serif Display" color="#000000" fontSize="36px">
              Let's Talk
            </Typography>
            <Typography textAlign="left" fontFamily="Jost" color="#4F4F4F" fontSize="16px">
              Reach out to consult and discover how we can work together to drive impactful change.
            </Typography>
          </div>
          <Divider className="col-12 col-md-1 d-none d-md-block" orientation="vertical" style={{ backgroundColor: '#000000', height: '100%' }} />
          <div className="col-12 col-md-6">
            <Typography textAlign="left" fontSize="16px" fontFamily="Jost" color="#4F4F4F">
              Phone: <LocalPhoneOutlinedIcon /> +254 719 814 734
            </Typography>
            <Typography textAlign="left" fontSize="16px" fontFamily="Jost" color="#4F4F4F">
              Email: <MailOutlinedIcon /> gathonimutitu80@gmail.com
            </Typography>
            <Typography textAlign="left" fontSize="16px" fontFamily="Jost" color="#4F4F4F">
              Nairobi, Kenya
            </Typography>
            <Typography textAlign="left" fontSize="16px" fontFamily="Jost" color="#4F4F4F">
              Available for remote or relocation
            </Typography>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Typography variant="body1" component="span" fontFamily="Jost" color="#333333">
            © {currentYear} Elizabeth Mutitu
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Experience;
