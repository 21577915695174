import './App.css';
import {Routes,Route} from 'react-router-dom'
// import Comingsoon from './components/soon-layout/Soon'
import Maincontent from './components/main-layout/Main';
import 'animate.css'
function App() {
  return (
    <div className="App">
  
      <Routes>
        <Route path="/" element={<Maincontent/>}/>
      </Routes>
      

    </div>
  );
}


export default App;
