import "./Intro.css";
import React from 'react';
import { motion } from 'framer-motion';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const ElizabethIntroduction = () => {
  return (
    <div className='container elizabeth-introduction'>
      <div className="row">
        <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center order-1 order-lg-2">
          <motion.div
            className="elizabeth-image-container"
            whileHover={{ scale: 1.1, rotate: 3 }}
            whileTap={{ scale: 0.9, rotate: -3 }}
            initial={{ scale: 1.1 }}
            whileInView={{ scale: 1, transition: { duration: 0.1, delay: 0.2 } }}
          >
            <img className="img-fluid" src="/img/img4.webp" alt="Elizabeth" />
          </motion.div>
        </div>
        <div className="col-12 col-lg-7 content order-2 order-lg-1">
          <h4 className="introduction-heading">HI THERE</h4>
          <p className="name-heading" style={{ color: 'black' }}>
            I’m Elizabeth Mutitu,
            <br />
            A Development Finance Professional & a Pan-African Feminism Enthusiast.
          </p>
          <h3 className="passionate-heading">Dive in to explore my world of grants management, compliance, budgeting and institutional strengthening. I'm delighted to share my journey with you and I hope you find joy and inspiration in my work. 🤩</h3>
          <div className="additional-info d-flex flex-column flex-sm-row">
            <h3 className="experience-heading mb-2 mb-sm-0">
              <CalendarMonthOutlinedIcon style={{ fontSize: '1.5rem', marginRight: '0.5rem' }} />
              7+ Years of Experience
            </h3>
            <a 
              href="https://elizabethmutitu.s3.amazonaws.com/ELIZABETH_GATHONIMUTITU_Resume.pdf" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <h3 className="resume-heading mt-2 mt-sm-0">
                Here’s my resume
                <FileDownloadOutlinedIcon style={{ fontSize: '1.5rem', marginRight: '0.5rem' }} />
              </h3>
            </a>
          </div>
          <button className="email-button" style={{color: '#FF826E', borderColor: '#FF826E', marginTop: '30px'}}>
          Shoot Me An Email
        </button>
        </div>
      </div>
    </div>
  );
};

export default ElizabethIntroduction;
